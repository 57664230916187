import { bindable } from 'aurelia-framework';

export class UxBlade {
    @bindable() private beforeHide: () => void;
    @bindable() private afterHide: () => void;
    @bindable() private beforeShow: () => void;
    @bindable() private afterShow: () => void;
    private element: HTMLElement;

    public async show(): Promise<void> {
        return new Promise((resolve) => {
            if (this.beforeShow) this.beforeShow();
            if (!this.element.classList.contains('is-active')) {
                this.element.classList.add('is-active');

                setTimeout(() => {
                    if (this.afterShow) this.afterShow();

                    return resolve();
                }, 300);
            } else
                return resolve();
        });
    }

    public async hide(): Promise<void> {
        return new Promise((resolve) => {
            if (this.beforeHide) this.beforeHide();
            if (this.element.classList.contains('is-active')) {
                this.element.classList.remove('is-active');

                setTimeout(() => {
                    if (this.afterHide) this.afterHide();

                    return resolve();
                }, 300);
            } else
                return resolve();
        });
    }

    public toggle(): void {
        if (this.element.classList.contains('is-active'))
            this.element.classList.remove('is-active');
        else
            this.element.classList.add('is-active');
    }
}
