import { autoinject, bindable } from 'aurelia-framework';
import { NavModel, Router } from 'aurelia-router';
import { StateApi } from '../../../services/state-api';
import { CompanyLightweight, UserProfileSettings } from '../../../services/cyber-api';
import { EventKeys } from '../../../enums/event-keys';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AureliaConfiguration } from 'aurelia-configuration';

@autoinject()
export class UxSidemenu {
    @bindable userProfileSettings: UserProfileSettings;
    private navModels: NavModel[];
    private showTitle = false;
    private company: CompanyLightweight;
    private pinned: boolean | undefined = undefined;
    @bindable enableMagnification = true;
    @bindable enableExpand = true;

    constructor(
        protected router: Router,
        protected state: StateApi,
        protected eventAggregator: EventAggregator,
        protected config: AureliaConfiguration,
    ) {
    }

    private attached(): void {
        this.navModels = this.router.navigation;
        this.enableExpand = !this.navModels.some(x => x.settings.showTitle)

        const companyState = this.state.company();
        // Retrieve companies from the state cache
        const companies = this.state.companies() || [];
        this.company = companies.find((c) => c.id === companyState);
    }

    private userProfileSettingsChanged(newValue: UserProfileSettings): void {
        this.pinned = newValue.pinSideMenu;
    }

    private enableMenu(navModel: NavModel): boolean {
        const canAccessVulnerabilityScanner = this.config.get('vulnerabilityScannerAllowedCompanies', []);

        const isEdr = navModel.settings.isEdr;
        const edrActive = this.company.isEdrActive;
        const isThreatDetection = navModel.settings.isThreatDetection;
        const threatDetectionActive = this.company.isThreatDetectionActive;
        const isVulnerabilityScanner = navModel.settings.isVulnerabilityScanner;
        const vulnerabilityScannerActive = canAccessVulnerabilityScanner.includes(this.company.id.toLowerCase());

        if (isEdr && isThreatDetection) {
            return edrActive || threatDetectionActive;
        } else if (isEdr) {
            return edrActive;
        } else if (isThreatDetection) {
            return threatDetectionActive;
        } else if (isVulnerabilityScanner) {
            return vulnerabilityScannerActive;
        }
        return true;
    }

    private showMenu(navModel: NavModel): boolean {
        const canAccessVulnerabilityScanner = this.config.get('vulnerabilityScannerAllowedCompanies', []);

        const isVulnerabilityScanner = navModel.settings.isVulnerabilityScanner;
        const vulnerabilityScannerActive = canAccessVulnerabilityScanner.includes(this.company.id.toLowerCase());

        if (isVulnerabilityScanner) {
            return vulnerabilityScannerActive;
        }
        return true;
    }

    private togglePinned(): void {
        if( this.pinned === undefined ) {
            // userprofile is not loaded yet
            this.pinned = true;
            return;
        }
        this.pinned = !this.pinned;

        // publish event to notify other components of timezone change
        this.eventAggregator.publish(EventKeys.onPinSideMenuChanged);
    }
}
